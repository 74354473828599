import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MapComponent from "../../components/MapComponent";
import "./EndRide.css";
import {
  BsArrowLeftShort,
  BsFillRecord2Fill,
  BsFillChatLeftTextFill,
} from "react-icons/bs";
import { IoIosArrowUp, IoIosCall, IoIosArrowDown } from "react-icons/io";
import { color } from "framer-motion";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";

// const driverDetail = {
//   id: "1",
//   name: "Cameron Williamson",
//   color: "White",
//   vehicle: "Nissan Altima",
//   licensePlate: "CTZ 1857",
// };

const destinations = [
  {
    name: "Bailey Drive, Fredericton",
    address: "9 Bailey Drive, Fredericton, NB E3B 5A3",
    destinationType: "departure",
  },
  {
    name: "Island Pkwy, Kamloops",
    address: "225 Belleville St, Victoria, BC V8V 1X1",
    destinationType: "arrival",
  },
];

const EndRide = () => {
  const navigate = useNavigate();
  const savedLat = localStorage.getItem("currentLat");
  const savedLng = localStorage.getItem("currentLng");

  // Convert them back to numbers since localStorage stores everything as strings
  const initialLat = savedLat ? parseFloat(savedLat) : null;
  const initialLng = savedLng ? parseFloat(savedLng) : null;

  const location = useLocation();
  const { state } = location;
  console.log(state);
  const { ride, riderData, tripId } = state;
  const { sourceLat, sourceLng, destinationLat, destinationLng } = riderData;
  const [directions, setDirections] = useState(null);
  const [googleProp, setGoogleProp] = useState(null);
  const [googleMap, setGoogleMap] = useState(null);
  const [driverLat, setDriverLat] = useState(null);
  const [driverLng, setDriverLng] = useState(null);

  useEffect(() => {
    const subscribe = onSnapshot(doc(firestore, "Rides", tripId), (doc) => {
      if (doc.exists()) {
        const ride = doc.data();
        if (ride.status === "tripCompleted") {
          navigate("/triproute", { state: { ride, tripId } });
        }
      }
    });

    return subscribe;
  }, [navigate, tripId]);

  const loadDirections = ({ google, map, driverLat, driverLng }) => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      map,
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: "red"
      }
    });
    const driverOrigin = new google.maps.LatLng(driverLat, driverLng);
    const driverDestination = new google.maps.LatLng(destinationLat, destinationLng);
  
    const request = {
      origin: driverOrigin,
      destination: driverDestination,
      travelMode: google.maps.TravelMode.DRIVING
    };
  
    directionsService.route(request, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
        directionsRenderer.setDirections(result);
        const leg = result.routes[0].legs[0];
        if (leg) {
          makeMarker(leg.start_location, "/assets/images/DriverIcon.png");
        }
      } else {
        console.error('Error fetching directions:', status);
      }
    });
  }
  const makeMarker = (position, icon) => {
    if (googleProp && googleMap) {
      new googleProp.maps.Marker({
        map: googleMap,
        position: position,
        icon: {
          url: icon,
          scaledSize: new googleProp.maps.Size(40, 40), // Customize size as needed
        },
      });
    }
  };

  useEffect(() => {
    if (googleProp && googleMap && driverLat && driverLng) {
      loadDirections({
        google: googleProp,
        map: googleMap,
        driverLat,
        driverLng,
      });
    }
  }, [googleProp, googleMap, driverLat, driverLng]);

  // useEffect(() => {
  //   const unsubscribe = onSnapshot(doc(firestore, "location", ride.driver), (doc) => {
  //     if (doc.exists()) {
  //       const loc = doc.data();
  //       const {_lat, _long} = loc.g.geopoint;
  //       setDriverLat(_lat);
  //       setDriverLng(_long);
  //     }
  //   });
  
  //   return () => unsubscribe();
  // }, [ride, googleMap, googleProp]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, "location", ride.driver),
      (doc) => {
        if (doc.exists()) {
          const loc = doc.data();
          const { _lat, _long } = loc.g.geopoint;
          console.log(
            "got diver location: ",
            loc,
            _lat,
            _long,
            googleMap,
            googleProp
          );
          setDriverLat(_lat);
          setDriverLng(_long);
        }
      }
    );

    return unsubscribe;
  }, [ride, googleMap, googleProp]);

  const onMapLoad = (mapProps, map) => {
    const { google } = mapProps;
    setGoogleProp(google);
    setGoogleMap(map);
  };

  return (
    <div className="home-screen">
      <MapComponent
        lat={initialLat}
        lng={initialLng}
        onMapLoad={onMapLoad}
        directions={directions}
      />

      <div className="map-container">
        <div className="map-overlay">
          <div className="endheader">
            <button className="endback-button" onClick={() => navigate(-1)}>
              <BsArrowLeftShort className="endback-button" />
            </button>
          </div>
          <div className="endcurrent-location-bar mt-5">
            <div className="endcurrent-location-text">
              Reaching Destination in
              <div className="mintext">
                {directions?.routes[0].legs[0].duration.text || "..."}
              </div>
            </div>
          </div>
          <EndRideSection directions={directions} />
        </div>
      </div>
    </div>
  );
};

const EndRideSection = (props) => {
  const navigate = useNavigate();
  const [driverDetail, setDriverDetail] = useState({});
  const { state } = useLocation();
  const { ride } = state;
  const { directions } = props;
  const [isOpen, setOpen] = useState(true);

  useEffect(() => {
    getDriverInfo(ride.driver);
  }, [ride]);

  const getDriverInfo = async (driverId) => {
    console.log("gettig driver info: ", driverId, ride);
    const driverDocRef = doc(firestore, "User", driverId);
    const driverDoc = await getDoc(driverDocRef);

    if (driverDoc.empty) {
      throw new Error("There is no information for this driver");
    }

    const driver = driverDoc.data();


    const color = driver.vehicle && driver.vehicle.color ? driver.vehicle.color: "";
    const make = driver.vehicle && driver.vehicle.make ? driver.vehicle.make: "";
    const model = driver.vehicle && driver.vehicle.model ? driver.vehicle.model: "";
    const vehicleNumber = driver.vehicle && driver.vehicle.vehicleNumber ? driver.vehicle.vehicleNumber: "";

    const driverDetail = {
      id: driverId,
      name: driver.name,
      color: color,
      vehicle: make + " " + model,
      licensePlate: vehicleNumber,
      image: driver.image,
    };
    console.log(driverDetail);

    setDriverDetail(driverDetail);
  };

  const onCancelRide = () => {
    console.log("Clicked");
    navigate("/triproute");
  };

  const cancelRide = () => (
    <button className="endbuttonStyleFw" onClick={onCancelRide}>
      End Ride
    </button>
  );

  // Add any logic or state related to recent destinations here
  return (
    <div className="recent-destinations-container">
      <div
        className="recent-destinations-bar"
        onClick={() => setOpen(!isOpen)}
      ></div>
      {isOpen && (
        <div>
          <div className="">
            <div
              style={{
                alignItems: "center",
                marginTop: "20px" /* Adjust as needed */,
              }}
            >
              {/* <IoIosCall className="endcall-icon" /> */}
              <img
                // src={"/assets/images/users/user2.png"} // Adjust the path as needed
                src={driverDetail.image}
                alt="driver detial" // Adjust the path as needed
                className="enddriver-image" // Adjust height as needed
              />
              {/* <BsFillChatLeftTextFill  className="endtext-icon"/> */}

              <div className="enddriver-name">{driverDetail.name}</div>
              <div className="end-description-container">
                <div className="end-description mb-4">
                  <div className="end-description-item">
                    <div className="end-description-item-subtitle">
                      {driverDetail.color} {driverDetail?.vehicle}
                    </div>
                    <div className="end-description-item-details">
                      {driverDetail.licensePlate}
                    </div>
                  </div>
                  <div className="end-description-item">
                    <div className="end-description-item-subtitle">
                      Arriving in
                    </div>
                    <div className="end-description-item-details">
                      {directions?.routes[0].legs[0].duration.text ?? "..."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ flex: "1" }}> {cancelRide()}</div> */}
        </div>
      )}
    </div>
  );
};

export default EndRide;
